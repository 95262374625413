* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.black-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
}

.blog {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: 1.4rem;
    font-weight: 200;
    color: #ffffff;
}

.blog-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.blog .content {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.blog .intro {
    margin: 0 auto;
    padding: 7vh 10vw; 
    text-align: left;
    width: 60%;
}

.blog-posts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    align-items: center;
}

.blog-post-box {
    display: flex;
    padding: 1rem;
    width: 100%;
    margin-left: 0;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
}

.blog-post-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.blog-post-box h2 {
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    transition: color 0.3s ease;
    margin-top: 0.5rem;
}

.blog-post-box:hover {
    transform: translateY(-3px);
    background-color: rgba(255, 255, 255, 0.1);
}

.blog-post-box:hover h2,
.blog-post-box:hover .tag {
    color: rgba(255, 255, 255, 1);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tag {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    padding: 0.3rem 0.8rem;
    border-radius: 9999px;
    font-size: 0.9rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    white-space: nowrap;
    transition: all 0.3s ease;
}

.tag:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 1);
}

.tag-icon {
    margin-right: 0.3rem;
}

@media screen and (max-width: 768px) {
    .blog .intro {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 5vh 0;
    }

    .blog .intro p {
        font-size: calc(1rem + 0.5vw);
    }

    .three-column-layout {
        flex-direction: column;
    }

    .column {
        width: 100%;
    }

    .blog-bento-box {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }

    .blog-post-box {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }

    .blog-post-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .blog-post-box h2 {
        font-size: calc(1rem + 0.7vw);
        margin-left: 0;
        margin-top: 0.5rem;
        width: 100%;
    }

    .tags {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .tag {
        font-size: calc(0.6rem + 0.6vw);
        padding: calc(0.2rem + 0.2vw) calc(0.4rem + 0.4vw);
    }
}

.three-column-layout {
    display: flex;
    width: 100%;
}

.column {
    flex: 1;
}

.blog-bento-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    border-radius: 10px;
    background-color: transparent; /* Fully transparent */
}

.blog-post-box:hover {
    transform: translateY(-3px); /* Slight lift effect on hover */
}