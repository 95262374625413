* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.contact-page {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.black-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
}

.contact-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Override header and footer styles for the contact page */
.contact-page .header,
.contact-page .footer {
    background-color: transparent;
}

.contact-content .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    padding: 2rem;
}

.contact-content .pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem; 
}

.contact-content .pic-image-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 0.5rem; 
}

.contact-content .pic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-content .social-icons {
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
}

.contact-content .social-icons a {
    color: white;
    transition: color 0.3s ease;
}

.contact-content .social-icons a:hover {
    color: rgba(22, 10, 237, 0.899);
}

.contact-content .intro {
    text-align: center;
    margin-top: 0rem;
}

@media screen and (max-width: 768px) {
    .contact-content .pic-image-container {
        width: 150px;
        height: 150px;
    }

    .contact-content .social-icons {
        font-size: 1.2rem;
    }
}