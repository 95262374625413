.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    height: auto;
    background-color: transparent;
    color: white;
    margin-top: auto;
}

.footer .location {
    font-size: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    width: 100%;
    padding: 1vh 2vw;
    margin-top: 3vh; /* Increased space between content and footer */
}

.footer .location .divider {
    margin: 0 0.5rem; /* Increased margin to create more space around the divider */
    width: 1px;
    height: 1em;
    background-color: white;
    display: inline-block;
    vertical-align: middle;
}

.footer .location .time,
.footer .location .place {
    flex: 1; /* Allow time and place to take up equal space */
    text-align: center; /* Center the text within each flex item */
}

.footer .location .time {
    text-align: right; /* Align time to the right */
}

.footer .location .place {
    text-align: left; /* Align place to the left */
}

.switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3vh 0; 
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.slider.round {
    border-radius: 20px;
}

.slider.round:before {
    border-radius: 50%;
}

.footer .copyright {
    font-size: 0.7rem;
    opacity: 0.7;
    padding: 0.5vh 0.5vw; 
    margin: 0;
    width: 100%;
    text-align: left; /* Keep copyright text aligned to the left */
}

@media screen and (max-width: 768px) {
    .footer .location {
        font-size: calc(0.7rem + 0.5vw);
        padding: 1vh 1vw;
    }

    .footer .copyright {
        font-size: calc(0.5rem + 0.5vw);
        padding: 0.5vh 0.5vw;
    }

    .footer .location .divider {
        margin: 0 0.5rem; /* Adjust margin for smaller screens */
    }

    .footer .location .time,
    .footer .location .place {
        margin-right: 0;
        margin-left: 0;
    }

    .switch {
        width: 30px;
        height: 18px;
    }

    .slider:before {
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
    }

    input:checked + .slider:before {
        transform: translateX(12px);
    }
}