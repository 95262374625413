* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*
YOU CAN EDIT THE GRADIENT HERE
CAUSES GLOBAL CHANGE:
*/

#gradient-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    --gradient-color-1: #0ef0b8; 
    --gradient-color-2: #0f595ed8; 
    --gradient-color-3: #F6CD61;  
    --gradient-color-4: #F24D16;
}

p, h1 {
    margin: 0;
    padding: 0;
}

.homepage body {
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 1.55rem;
    background-color: black; 
    color: #ffffff; 
    text-align: left;
}

.homepage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.black-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
}

.homepage-content {
    display: flex;
    flex-direction: column;
    flex: 1; /* Add this line */
}

.homepage .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
    padding: 0 2vw;
}

.homepage .research-section {
    margin-bottom: 2rem;
    width: 100%; /* Ensure full width within the content area */
}

.homepage .research-section h1 {
    font-family: 'Noto Sans Georgian', sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    color: white;
    margin-top: 3vh;
    text-align: center;
}

.homepage .research-intro {
    padding: 3vh 0;
}

.homepage .research-pubs {
    margin: 0;
    padding: 0;
    font-size: 1rem;
}

.homepage .projects-section {
    width: 100%;
    max-width: 60vw;
}

.homepage .projects h1 {
    font-family: 'Noto Sans Georgian', sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    color: white;
    margin-top: 3vh;
    text-align: center;
}

.homepage .project-box {
    background-color: #1a1a1a;
    border: 0.0625rem solid #ffffff; 
    border-radius: 0.625rem; 
    padding: 1.25rem; 
    margin-top: 3vh; 
    margin-bottom: 1.25rem;
    color: #ffffff;
    text-decoration: none;
    display: grid;
    grid-template-columns: 1fr 3fr; 
    gap: 0; /* Remove any gap between grid columns */
    transition: background-color 0.3s, transform 0.3s; 
    transform: translateY(0); 
    position: relative; 
}

.homepage .project-box::after {
    content: '';
    position: absolute;
    top: 1.25rem;
    bottom: 1.25rem;
    left: 25%; 
    width: 0.0625rem;
    background-color: #ffffff; 
}

.homepage .project-box:hover {
    background-color: #979696cd;
    transform: translateY(-0.3125rem); 
}

.homepage .project-box .icon {
    grid-column: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1.25rem; 
    font-size: 2.25rem; 
}

.homepage .project-box .content {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.25rem;
    width: 100%; 
}

.homepage .project-box .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.625rem; 
    transition: transform 0.3s; 
    text-align: left;
    width: 100%; 
}

.homepage .project-box .text {
    font-size: 0.9rem;
    line-height: 1.4;
    transition: transform 0.3s;
    text-align: left;
    width: 100%; 
}

.homepage .project-box:hover .title,
.homepage .project-box:hover .text,
.homepage .project-box:hover .icon {
    transform: translateY(-0.150rem); 
}

.homepage.project .title {
    max-width: 50vw;
    text-align: left;
    padding: 0 2vw 1vh 2vw;
}

.homepage.project-carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.homepage .project-boxes {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; 
    -ms-overflow-style: none; 
    gap: 1rem;
    padding: 0 0.5rem;
    scroll-behavior: smooth;
}

.homepage .project-boxes::after {
    content: '';
    padding-right: 0.5rem;
}

.homepage .project-box {
    flex: 0 0 100%;
    scroll-snap-align: start;
    margin-right: 1rem;
}

.homepage .project-box:last-child {
    margin-right: 0;
}

.homepage .carousel-dots {
    display: flex;
    justify-content: center;
    margin-top: -0.5rem;
}

.homepage .dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #555;
    margin: 0 0.25rem;
    transition: background-color 0.3s;
}

.homepage .dot.active {
    background-color: #fff;
}

.homepage .research {  
    margin-bottom: 2rem; 
}

.homepage .research h1 {
    font-family: 'Noto Sans Georgian', sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    color: white;
    margin-top: 0vh;
    text-align: center;
}

.homepage .research-intro {
    padding: 1.5vh 0;
}

.homepage .research-pubs {
    margin: 0; 
    padding: 0vh 2vw; 
    font-size: 1rem;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .homepage body {
        font-size: calc(1rem + 1vw); /* Responsive base font size for mobile */
    }

    .homepage .content {
        max-width: 90vw;
        padding: 0;
    }

    .homepage .research-section {
        width: 100%;
        padding: 0;
    }

    .homepage .research-intro,
    .homepage .research-pubs {
        width: 100%;
        padding: 0;
    }

    .homepage .research-intro p,
    .homepage .research-pubs p {
        padding: 0 5vw; /* Increase padding for mobile */
    }

    .homepage .intro, .homepage .projects {
        padding: 3vh 5vw; /* Adjusted padding for mobile */
    }

    .homepage .intro p {
        font-size: calc(1rem + 0.5vw);
    }

    .homepage .projects h1,
    .homepage .research-section h1 {
        font-size: calc(1.2rem + 1vw);
    }

    .homepage .project-box .title {
        font-size: calc(1rem + 0.5vw); 
    }

    .homepage .project-box .text {
        font-size: calc(0.8rem + 0.3vw);
    }

    .homepage .research {
        width: 90vw; 
        padding: 0; 
    }

    .homepage .research-intro {
        width: 90vw; 
        padding: 1.5vh 0; 
        font-size: calc(1rem + 0.5vw);
    }

    .homepage .research-pubs {
        width: 95vw;
        font-size: calc(0.8rem + 0.5vw);
    }
}