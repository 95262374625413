html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 200;
  font-size: 1.4rem;
  background-color: black;
  color: #ffffff;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}