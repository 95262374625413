.header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto auto; 
    margin-top: 4vh;
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.header .nav-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    grid-row: 1; 
    grid-column: 1 / -1; 
    display: contents; 
}

.header .nav-item {
    font-family: 'Noto Sans Georgian', sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    line-height: 1;
    text-decoration: none; 
    transition: color 0.3s; 
}

.header .nav-item:hover {
    color: rgba(255, 255, 255, 0.7);
}

.header .nav-item.one-1 {
    grid-column: 2;
}

.header .nav-item.one-2 {
    grid-column: 3; 
}

.header .nav-item.one-3 {
    grid-column: 4;
}

.header .divider {
    grid-column: 2 / 5; 
    grid-row: 2; 
    height: 1px; 
    background-color: white; 
    margin: 2vh 0; 
    align-self: center; 
}

@media screen and (max-width: 768px) {
    .header {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        margin-top: 2vh;
        padding: 0;
        width: 100vw;
        max-width: none;
    }

    .header .nav-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .header .nav-item {
        font-size: calc(1.2rem + 1vw);
        justify-content: center;
        padding: 1vh 0;
        width: 100%;
        text-align: center;
    }

    .header .upper-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 5vw;
    }

    .header .divider {
        grid-column: 1;
        width: 90vw; 
        margin: 2vh auto;
    }
}